const defaultSubmission = {
  consented: false,
  consented_at: "",
  printed_name: "",
  files: [],
  gender: "",
  transgender: "",
  is_hispanic_or_latino: "",
  race: "",
  age: "",
  partner: "",
  dependents: "",
  income: "",
  accepted: "",
  security_deposit: "",
  rent: "",
  bedrooms: "",
  house_type: "",
  voucher: "",
  minimum_rent: "",
  landlord_name: "",
  landlord_scale: "",
  property_address: "",
  property_address_city: "",
  property_address_state: "",
  property_address_zipcode: "",
  experience_freeform: "",
  interview_possible: "",
  email: "",
  email_confirm: "",
  report_date_month: null,
  report_date_year: null,
  rent_apply_date_month: null,
  rent_apply_date_year: null

}

export default defaultSubmission;





// consented: false,
// consented_at: "",
// printed_name: "Wonyoung So",
// files: [],
// gender: "man",
// transgender: "no",
// is_hispanic_or_latino: "no",
// race: "asian",
// age: "30-39",
// partner: "yes",
// dependents: "1",
// income: "3500",
// accepted: "accepted",
// security_deposit: "2500",
// rent: "2500",
// bedrooms: "1",
// house_type: "multifamily",
// voucher: "yes",
// minimum_rent: "25",
// landlord_name: "VIVA",
// landlord_scale: "individual",
// property_address: "45",
// property_address_city: "Cambridge",
// property_address_state: "Massachusetts",
// property_address_zipcode: "02142",
// experience_freeform: "dfije",
// interview_possible: "yes",
// email: "wonyoung.so.work@gmail.com",
// email_confirm: "wonyoung.so.work@gmail.com",
// report_date_month: "May",
// report_date_year: "2022",
// rent_apply_date_month: "May",
// rent_apply_date_year: "2022"